import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import Navigation from "./Navigation";

const AppLayout = ({ children, darkMode, toggleDarkMode }) => {
  const { currentUser } = useAuth();
  const shouldShowNavigation = currentUser && (currentUser.isGuest || currentUser.email);

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        bgcolor: "background.default",
      }}
    >
      {shouldShowNavigation && (
        <Navigation darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          ...(shouldShowNavigation && {
            ml: { xs: 0 },
            transition: (theme) =>
              theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
          }),
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  darkMode: PropTypes.bool.isRequired,
  toggleDarkMode: PropTypes.func.isRequired,
};

AppLayout.defaultProps = {
  darkMode: false,
  toggleDarkMode: () => {},
};

export default AppLayout;
