import { Home as HomeIcon, Person as PersonIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Link as MuiLink,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TypewriterText from "../components/common/TypewriterText";
import AuthCard from "./Auth/components/AuthCard";

export default function LandingPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const styles = useMemo(
    () => ({
      buttonContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        marginTop: theme.spacing(4),
        "@media (max-width: 600px)": {
          gap: theme.spacing(1.5),
          marginTop: theme.spacing(3),
        },
      },
      button: {
        padding: theme.spacing(2),
        borderRadius: 4,
        textTransform: "none",
        fontSize: "0.95rem",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(2),
        justifyContent: "flex-start",
        backgroundColor:
          theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, 0.05)"
            : "rgba(0, 0, 0, 0.02)",
        "&:hover": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.1)"
              : "rgba(0, 0, 0, 0.04)",
        },
        "@media (max-width: 600px)": {
          width: "100%",
          justifyContent: "flex-start",
          padding: theme.spacing(1.5),
          gap: theme.spacing(1.5),
          fontSize: "0.9rem",
        },
      },
      hostButton: {
        color: "#fff",
        backgroundColor: "#FF385C",
        "&:hover": {
          backgroundColor: "#E31C5F",
        },
        "& .MuiTypography-root": {
          color: "rgba(255, 255, 255, 0.95)",
        },
        "& .MuiTypography-subtitle1": {
          color: "rgba(255, 255, 255, 0.85)",
        },
      },
      icon: {
        fontSize: "2rem",
        "@media (max-width: 600px)": {
          fontSize: "1.8rem",
        },
      },
      buttonText: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: theme.spacing(0.5),
        "@media (max-width: 600px)": {
          alignItems: "flex-start",
          textAlign: "left",
        },
      },
      buttonTitle: {
        fontWeight: 600,
        fontSize: "1rem",
        lineHeight: 1.2,
        "@media (max-width: 600px)": {
          textAlign: "left",
        },
      },
      buttonSubtext: {
        fontSize: "0.875rem",
        color:
          theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, 0.7)"
            : "rgba(0, 0, 0, 0.6)",
        fontWeight: 400,
        "@media (max-width: 600px)": {
          textAlign: "left",
          width: "100%",
        },
      },
      welcomeText: {
        marginBottom: theme.spacing(4),
        textAlign: "center",
        "& > *": {
          fontSize: "1.15rem",
          lineHeight: 1.5,
          color:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.9)"
              : "rgba(0, 0, 0, 0.8)",
          "@media (max-width: 600px)": {
            fontSize: "1rem",
            lineHeight: 1.4,
          },
        },
      },
      subtitle: {
        textAlign: "center",
        marginBottom: theme.spacing(3),
        color:
          theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, 0.7)"
            : "rgba(0, 0, 0, 0.6)",
        fontSize: "0.95rem",
        fontWeight: 500,
        "@media (max-width: 600px)": {
          fontSize: "0.9rem",
          marginBottom: theme.spacing(2),
        },
      },
    }),
    [theme]
  );

  const welcomeMessages = t("auth.landing.welcomeMessages", {
    returnObjects: true,
  });

  const handleHostClick = useCallback(() => {
    navigate("/host");
  }, [navigate]);

  const handleGuestClick = useCallback(() => {
    navigate("/guest");
  }, [navigate]);

  const handleCreateGuideClick = useCallback(() => {
    navigate("/create-guide");
  }, [navigate]);

  return (
    <AuthCard
      welcomeTitle={t("auth.landing.title")}
      welcomeMessage={t("auth.landing.subtitle")}
    >
      <Box sx={{ textAlign: "center", mb: 3 }}>
        <TypewriterText messages={welcomeMessages} />
        <Typography variant="subtitle1" color="text.secondary">
          {t("auth.landing.welcomeBack")}
        </Typography>
      </Box>

      <Box sx={styles.buttonContainer}>
        <Button
          variant="contained"
          onClick={handleHostClick}
          sx={{ ...styles.button, ...styles.hostButton }}
        >
          <HomeIcon sx={styles.icon} />
          <Box sx={styles.buttonText}>
            <Typography sx={styles.buttonTitle}>
              {t("auth.landing.hostButton")}
            </Typography>
            <Typography sx={styles.buttonSubtext}>
              {t("auth.landing.hostSubtext")}
            </Typography>
          </Box>
        </Button>

        <Button
          variant="outlined"
          onClick={handleGuestClick}
          sx={styles.button}
        >
          <PersonIcon sx={styles.icon} />
          <Box sx={styles.buttonText}>
            <Typography sx={styles.buttonTitle}>
              {t("auth.landing.guestButton")}
            </Typography>
            <Typography sx={styles.buttonSubtext}>
              {t("auth.landing.guestSubtext")}
            </Typography>
          </Box>
        </Button>
      </Box>

      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            "& .MuiLink-root": {
              color: "primary.main",
              textDecoration: "none",
              fontWeight: 500,
              transition: "color 0.2s",
              "&:hover": {
                color: "primary.dark",
                textDecoration: "underline",
              },
            },
          }}
        >
          {t("auth.landing.newHostText")}{" "}
          <MuiLink component="button" onClick={handleCreateGuideClick}>
            {t("auth.landing.createGuideLink")}
          </MuiLink>
        </Typography>
      </Box>
    </AuthCard>
  );
}
