import {
  ChevronLeft,
  ChevronRight,
  Close as CloseIcon,
  DarkMode as DarkModeIcon,
  Dashboard as DashboardIcon,
  ExitToApp as ExitToAppIcon,
  Home as HomeIcon,
  LightMode,
  Login as LoginIcon,
  Map as MapIcon,
  Menu as MenuIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  SwipeableDrawer,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import CustomTooltip from "../common/tooltips/CustomTooltip";
import LanguageSelector from "./Languages/LanguageSelector";

import ConfirmationDialog from "../common/dialog/ConfirmationDialog";
import Logo from "./Logo";
import UserRole from "./UserRole";

const Navigation = ({ darkMode, toggleDarkMode }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { signOut, currentUser, checkUserRole } = useAuth();
  const [role, setRole] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const fetchRole = async () => {
      const userRole = await checkUserRole();
      setRole(userRole);
    };
    fetchRole();
  }, [checkUserRole]);

  const isHost = role === "host";

  const menuItems = [
    { text: t("common.home"), icon: <DashboardIcon />, path: "/dashboard" },
    {
      text: t("common.propertyInfo"),
      icon: <HomeIcon />,
      path: "/property-info",
    },
    {
      text: t("navigation.localTips"),
      icon: <MapIcon />,
      path: "/local-tips",
    },
    // { text: t("navigation.amenities"), icon: <MapIcon />, path: "/amenities" },
    { type: "divider" },
    ...(isHost
      ? [
          // {
          //   text: t("common.edit"),
          //   icon: <EditIcon />,
          //   path: "/management",
          //   admin: true,
          // },
          {
            text: t("common.settings"),
            icon: <SettingsIcon />,
            path: "/settings",
          },
        ]
      : []),
    {
      text: currentUser ? t("common.logout") : t("common.login"),
      icon: currentUser ? <ExitToAppIcon /> : <LoginIcon />,
      action: currentUser ? "logout" : "login",
      path: "/",
    },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigation = (path, action) => {
    if (action === "logout") {
      setIsDialogOpen(true);
      if (isMobile) {
        setMobileOpen(false);
      }
      return;
    }
    
    if (action === "login") {
      navigate("/");
    } else {
      navigate(path);
    }
    
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const handleConfirmSignOut = () => {
    signOut().then(() => navigate("/"));
    setIsDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const drawer = (
    <Box
      sx={{
        width: isExpanded ? 250 : 72,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          p: 2,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        {!isMobile && (
          <IconButton
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{
              color: "text.secondary",
              "&:hover": {
                backgroundColor: `${theme.palette.primary.main}08`,
              },
            }}
          >
            {isExpanded ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        )}
        {isMobile && (
          <IconButton
            onClick={handleDrawerToggle}
            sx={{
              color: "text.secondary",
              "&:hover": {
                backgroundColor: `${theme.palette.primary.main}08`,
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      <Logo darkMode={darkMode} isExpanded={isExpanded} />
      <UserRole />
      <List>
        {menuItems.map((item, index) =>
          item.type === "divider" ? (
            <Divider key={`divider-${index}`} sx={{ my: 1 }} />
          ) : (
            <ListItem
              button
              key={item.text}
              onClick={() => handleNavigation(item.path, item.action)}
              selected={location.pathname === item.path}
              sx={{
                minHeight: 48,
                justifyContent: isExpanded ? "initial" : "center",
                px: 2.5,
                borderRadius: 2,
                mb: 1,
                position: "relative",
                color:
                  location.pathname === item.path
                    ? "primary.main"
                    : "text.secondary",
                ...(item.admin && {
                  "&:hover": {
                    backgroundColor: (theme) =>
                      `${theme.palette.primary.main}15`,
                  },
                  "&.Mui-selected": {
                    backgroundColor: (theme) =>
                      `${theme.palette.primary.main}20`,
                    "&:hover": {
                      backgroundColor: (theme) =>
                        `${theme.palette.primary.main}30`,
                    },
                  },
                }),
                "&:hover": {
                  bgcolor: (theme) => `${theme.palette.primary.main}08`,
                  color: "primary.main",
                },
                "&::before":
                  location.pathname === item.path
                    ? {
                        content: '""',
                        position: "absolute",
                        left: -8,
                        top: "50%",
                        transform: "translateY(-50%)",
                        width: 4,
                        height: "60%",
                        bgcolor: "primary.main",
                        borderRadius: "0 4px 4px 0",
                      }
                    : {},
              }}
            >
              <CustomTooltip
                title={!isExpanded ? item.text : ""}
                placement="right"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isExpanded ? 3 : "auto",
                    justifyContent: "center",
                    color:
                      location.pathname === item.path
                        ? "primary.main"
                        : "inherit",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
              </CustomTooltip>
              {isExpanded && <ListItemText primary={item.text} />}
            </ListItem>
          )
        )}
      </List>
      <Box sx={{ mt: "auto", mb: 2, mx: 1 }}>
        <Divider sx={{ my: 2 }} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: isExpanded ? "space-between" : "center",
            px: isExpanded ? 1.5 : 0,
          }}
        >
          <LanguageSelector isCollapsed={!isExpanded} />
          <CustomTooltip title={t(darkMode ? "theme.light" : "theme.dark")}>
            <IconButton
              onClick={toggleDarkMode}
              sx={{
                width: 32,
                height: 32,
                color: "text.secondary",
                "&:hover": {
                  bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                },
              }}
            >
              {darkMode ? <LightMode /> : <DarkModeIcon />}
            </IconButton>
          </CustomTooltip>
        </Box>
      </Box>
      <Box
        sx={{
          p: 2,
          borderTop: `1px solid ${theme.palette.divider}`,
          display: "flex",
          justifyContent: "center",
        }}
      ></Box>
    </Box>
  );

  return (
    <>
      <Box
        component="nav"
        sx={{
          width: { md: isExpanded ? 250 : 72 },
          flexShrink: { md: 0 },
        }}
      >
        {isMobile ? (
          <SwipeableDrawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onOpen={handleDrawerToggle}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              "& .MuiDrawer-paper": { width: 250 },
            }}
          >
            {drawer}
          </SwipeableDrawer>
        ) : (
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", md: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: isExpanded ? 250 : 72,
                transition: theme.transitions.create("width", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
                overflowX: "hidden",
                borderRight: `1px solid ${theme.palette.divider}`,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        )}
      </Box>

      {isMobile && (
        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: theme.zIndex.appBar,
          }}
          elevation={3}
        >
          <BottomNavigation
            value={location.pathname}
            onChange={(event, newValue) => {
              const item = menuItems.find((i) => i.path === newValue);
              handleNavigation(newValue, item?.action);
            }}
            showLabels={false}
          >
            {menuItems
              .filter(item => 
                item.type !== "divider" && 
                (!isMobile || (item.icon.type !== HomeIcon && 
                              item.icon.type !== MapIcon &&
                              item.action !== "logout"))
              )
              .map((item) => (
                <BottomNavigationAction
                  key={item.path}
                  icon={item.icon}
                  value={item.path}
                />
              ))}
            <BottomNavigationAction
              icon={
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDrawerToggle();
                  }}
                  sx={{
                    color: "text.secondary",
                    "&:hover": {
                      backgroundColor: `${theme.palette.primary.main}08`,
                    },
                  }}
                >
                  <MenuIcon />
                </IconButton>
              }
              onClick={(e) => {
                e.stopPropagation();
                handleDrawerToggle();
              }}
            />
          </BottomNavigation>
        </Paper>
      )}

      <ConfirmationDialog
        open={isDialogOpen}
        icon={<ExitToAppIcon />}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmSignOut}
        title={t("")}
        content={t("common.confirmSignOut")}
      />
    </>
  );
};

export default Navigation;
