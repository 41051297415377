import { Box, Paper, Typography, useTheme } from "@mui/material";
import AnimatedBackground from "./Background/AnimatedBackground";
import BackgroundSlideshow from "./Background/BackgroundSlideshow";

const AuthCard = ({
  children,
  welcomeTitle,
  welcomeMessage,
  showLogo = true,
}) => {
  const theme = useTheme();
  const styles = {
    loginContainer: {
      minHeight: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: { xs: 1, sm: 2, md: 3 },
      position: "relative",
      background: "transparent",
    },
    loginCard: {
      width: "100%",
      maxWidth: { xs: "100%", sm: 450, md: 800 },
      display: "flex",
      flexDirection: { xs: "column", md: "row" },
      borderRadius: 3,
      overflow: "hidden",
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(30, 30, 30, 0.9)"
          : "rgba(255, 255, 255, 0.9)",
      backdropFilter: "blur(8px)",
      boxShadow:
        theme.palette.mode === "dark"
          ? "0 2px 10px rgba(0,0,0,0.3)"
          : "0 2px 10px rgba(0,0,0,0.05)",
      position: "relative",
      zIndex: 1,
    },
    loginForm: {
      width: { xs: "100%", md: "50%" },
      padding: { xs: 2, sm: 2.5, md: 3 },
      display: "flex",
      flexDirection: "column",
      order: { xs: 2, md: 1 },
    },
    loginImage: {
      width: { xs: "100%", md: "50%" },
      minHeight: { xs: 150, sm: 200, md: "auto" },
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      color: "#fff",
      padding: { xs: 2, sm: 2.5, md: 3 },
      position: "relative",
      overflow: "hidden",
      order: { xs: 1, md: 2 },
    },
    welcomeContent: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      position: "relative",
      zIndex: 2,
      textShadow: "0 2px 4px rgba(0,0,0,0.2)",
    },
    title: {
      fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem" },
      fontWeight: 700,
      marginBottom: 0.5,
      textAlign: "center",
      color: "white",
    },
    subtitle: {
      fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
      textAlign: "center",
      marginBottom: 1.5,
      color: "rgba(255, 255, 255, 0.9)",
    },
    logoContainer: {
      marginTop: { xs: 1.5, sm: 2 },
      width: { xs: "80px", sm: "100px", md: "120px" },
      position: "relative",
      zIndex: 2,
    },
    logo: {
      width: "100%",
      height: "auto",
      filter: "brightness(0) invert(1) drop-shadow(0 2px 4px rgba(0,0,0,0.2))",
      transition: "all 0.3s ease",
      opacity: 0.9,
      "&:hover": {
        transform: "scale(1.05)",
        opacity: 1,
      },
    },
  };

  return (
    <Box sx={styles.loginContainer}>
      <AnimatedBackground />
      <Paper elevation={0} sx={styles.loginCard}>
        <Box sx={styles.loginForm}>{children}</Box>
        <Box sx={styles.loginImage}>
          <BackgroundSlideshow />
          {!window.matchMedia("(max-width: 600px)").matches && (
            <Box sx={styles.welcomeContent}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 0.5,
                  mb: 2,
                }}
              >
                <Typography variant="h4" component="h2" sx={styles.title}>
                  {welcomeTitle}
                </Typography>
                <Typography variant="body1" sx={styles.subtitle}>
                  {welcomeMessage}
                </Typography>
              </Box>
            </Box>
          )}

          {showLogo && (
            <Box sx={styles.logoContainer}>
              <img src="/images/logo_full.png" alt="Logo" style={styles.logo} />
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default AuthCard;
