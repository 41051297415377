import {
  signOut as firebaseSignOut,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { get, ref, set } from "firebase/database";
import PropTypes from "prop-types";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { database } from "../config/firebase";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext(null);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const navigate = useNavigate();

  const checkUserRole = useCallback(async () => {
    const guestEmail = sessionStorage.getItem("guestEmail");
    if (guestEmail) return "guest";

    if (!auth.currentUser) return null;

    try {
      const roleRef = ref(database, `users/${auth.currentUser.uid}/role`);
      const snapshot = await get(roleRef);

      if (!snapshot.exists()) {
        await set(roleRef, "host");
        return "host";
      }

      return snapshot.val();
    } catch (error) {
      console.error("Error checking user role:", error);
      return null;
    }
  }, [auth.currentUser]);

  const signIn = useCallback(
    async (email, password) => {
      if (!email || !password) {
        console.warn("signIn: Missing email or password");
        return;
      }
      try {
        await signInWithEmailAndPassword(auth, email, password);
      } catch (error) {
        console.error("Sign in error:", error);
        throw error;
      }
    },
    [auth]
  );

  const signOut = useCallback(async () => {
    try {
      setCurrentUser(null); // Immediately set current user to null
      await firebaseSignOut(auth);
      sessionStorage.removeItem("guestEmail");
      sessionStorage.removeItem("propertyId");
      navigate("/");
    } catch (error) {
      console.error("Sign out error:", error);
      throw error;
    }
  }, [auth, navigate]);

  const hasGuestAccess = useCallback(async (email, propertyId) => {
    if (!email || !propertyId) {
      console.warn("hasGuestAccess: Missing email or propertyId");
      return false;
    }

    try {
      const guestAccessRef = ref(database, "guestAccess");
      const snapshot = await get(guestAccessRef);

      if (!snapshot.exists()) return false;

      const guests = Object.values(snapshot.val());
      const matchedGuest = guests.find((guest) => guest.email === email);

      if (!matchedGuest?.properties?.[propertyId]) return false;

      const accessData = matchedGuest.properties[propertyId];
      const isActive = accessData.status === "active";
      const isNotExpired =
        !accessData.accessExpires ||
        new Date(accessData.accessExpires) > new Date();

      return isActive && isNotExpired;
    } catch (error) {
      console.error("Guest access check error:", error);
      return false;
    }
  }, []);

  const getGuestProperties = useCallback(async (email) => {
    if (!email) return [];

    try {
      const guestAccessRef = ref(database, "guestAccess");
      const snapshot = await get(guestAccessRef);

      if (!snapshot.exists()) return [];

      const guests = Object.values(snapshot.val());
      const matchedGuest = guests.find((guest) => guest.email === email);

      if (!matchedGuest?.properties) return [];

      return Object.entries(matchedGuest.properties)
        .filter(([_, accessData]) => {
          const isActive = accessData.status === "active";
          const isNotExpired =
            !accessData.accessExpires ||
            new Date(accessData.accessExpires) > new Date();
          return isActive && isNotExpired;
        })
        .map(([propertyId, accessData]) => ({
          id: propertyId,
          ...accessData,
        }));
    } catch (error) {
      console.error("Error fetching guest properties:", error);
      return [];
    }
  }, []);

  const getUserGuides = useCallback(async () => {
    if (!auth.currentUser) return [];

    try {
      const userGuidesRef = ref(
        database,
        `users/${auth.currentUser.uid}/properties`
      );
      const guidesSnapshot = await get(userGuidesRef);

      if (!guidesSnapshot.exists()) return [];

      const properties = guidesSnapshot.val();
      const propertyPromises = Object.entries(properties).map(
        async ([propertyId, propertyData]) => {
          const propertyRef = ref(database, `properties/${propertyId}`);
          const propertySnapshot = await get(propertyRef);

          if (!propertySnapshot.exists()) return null;

          return {
            id: propertyId,
            ...propertyData,
            ...propertySnapshot.val(),
          };
        }
      );

      const results = await Promise.all(propertyPromises);
      return results.filter(Boolean);
    } catch (error) {
      console.error("Error fetching user guides:", error);
      return [];
    }
  }, [auth.currentUser]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        // Check for guest user first
        const guestEmail = sessionStorage.getItem("guestEmail");
        const guestData = sessionStorage.getItem("guestData");
        const propertyId = sessionStorage.getItem("propertyId") || localStorage.getItem("lastPropertyId");

        if (guestEmail) {
          const guestUser = guestData ? JSON.parse(guestData) : {
            email: guestEmail,
            isGuest: true,
            propertyId: propertyId
          };
          
          console.log("Setting guest user:", guestUser);
          setCurrentUser(guestUser);
          setLoading(false);
          return;
        }

        // Handle regular Firebase auth user
        if (user) {
          setCurrentUser(user);
        } else {
          setCurrentUser(null);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error in onAuthStateChanged:", error);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const contextValue = useMemo(
    () => ({
      currentUser,
      setCurrentUser,
      loading,
      signIn,
      signOut,
      checkUserRole,
      hasGuestAccess,
      getUserGuides,
    }),
    [
      currentUser,
      setCurrentUser,
      loading,
      signIn,
      signOut,
      checkUserRole,
      hasGuestAccess,
      getUserGuides,
    ]
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
