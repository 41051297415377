import { BR, ES, FR, US } from "country-flag-icons/react/3x2";

export const languages = [
  {
    code: "pt",
    name: "Portuguese",
    nativeName: "Português",
    region: "Brasil",
    flag: BR,
  },
  {
    code: "en",
    name: "English",
    nativeName: "English",
    region: "United States",
    flag: US,
  },
  {
    code: "fr",
    name: "French",
    nativeName: "Français",
    region: "France",
    flag: FR,
  },
  {
    code: "es",
    name: "Spanish",
    nativeName: "Español",
    region: "España",
    flag: ES,
  },
];

export const getLanguageByCode = (code) =>
  languages.find((lang) => lang.code === code) || languages[0];
