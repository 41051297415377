import {
  Box,
  Button,
  DialogContent,
  DialogContentText,
  Fade,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseDialog from "./BaseDialog";

const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  cancelLabel,
  deleteLabel,
  icon,
  iconSize = 64,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const actions = [
    <Button
      key="cancel"
      onClick={onClose}
      variant="outlined"
      sx={{
        minWidth: 120,
        color: "text.primary",
        borderColor: alpha(theme.palette.divider, 0.8),
        "&:hover": {
          borderColor: "text.primary",
          bgcolor: alpha(theme.palette.text.primary, 0.04),
        },
      }}
    >
      {cancelLabel || t("common.cancel")}
    </Button>,
    <Button
      key="delete"
      onClick={onConfirm}
      variant="contained"
      sx={{
        minWidth: 120,
        "&:hover": {
          boxShadow: `0 6px 16px ${alpha(theme.palette.error.main, 0.3)}`,
          transform: "translateY(-1px)",
        },
        transition: "all 0.2s ease-in-out",
      }}
    >
      {deleteLabel || t("common.exit")}
    </Button>,
  ];

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      title={title}
      maxWidth="xs"
      fullWidth={false}
      actions={actions}
    >
      <DialogContent sx={{ pt: 3, px: 4 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            py: 2,
          }}
        >
          {icon && (
            <Fade in={open} timeout={300}>
              <Box
                sx={{
                  mb: 3,
                  width: iconSize,
                  height: iconSize,
                  color: "primary.main",
                  "& svg": {
                    width: "100%",
                    height: "100%",
                    fill: "currentColor",
                  },
                }}
              >
                {icon}
              </Box>
            </Fade>
          )}
          <DialogContentText
            id="confirmation-dialog-description"
            sx={{
              color: "text.primary",
              fontSize: "0.9375rem",
              maxWidth: "90%",
              lineHeight: 1.5,
              mb: icon ? 0 : 2,
            }}
          >
            {content}
          </DialogContentText>
        </Box>
      </DialogContent>
    </BaseDialog>
  );
};

export default ConfirmationDialog;
