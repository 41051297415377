import React from 'react';
import { DialogTitle, IconButton, Box, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const DialogHeader = ({ title, icon: Icon, onClose }) => {
  return (
    <DialogTitle>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: 48
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          {Icon && <Icon color="primary" />}
          <Typography variant="h6" component="span">
            {title}
          </Typography>
        </Box>
        {onClose && (
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            size="small"
            sx={{
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </DialogTitle>
  );
};

export default DialogHeader;