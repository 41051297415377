import { Box, Typography, alpha } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";

const UserRole = () => {
  const { checkUserRole } = useAuth();
  const [role, setRole] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchRole = async () => {
      const userRole = await checkUserRole();
      setRole(userRole);
    };
    fetchRole();
  }, [checkUserRole]);

  if (!role) return null;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        px: 2,
        py: 1,
        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
        borderRadius: 1,
        mb: 2,
      }}
    >
      <Typography variant="body2" sx={{ fontWeight: 500 }}>
        {role === "host" ? t("common.roleHost") : t("common.roleGuest")}
      </Typography>
    </Box>
  );
};

export default UserRole;
