import { Google, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link as MuiLink,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import TypewriterText from "../../components/common/TypewriterText";
import { auth } from "../../config/firebase";
import { initializeUserData } from "../../utils/userUtils";
import AuthCard from "./components/AuthCard";

export default function HostLogin() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      await initializeUserData(userCredential.user);
      navigate("/dashboard");
    } catch (error) {
      console.error("Login error:", error);
      setError(t("auth.login.errors.invalidCredentials"));
    } finally {
      setLoading(false);
    }
  };

  const styles = {
    textField: {
      marginBottom: theme.spacing(2.5),
      "& .MuiOutlinedInput-root": {
        borderRadius: 4,
        backgroundColor:
          theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, 0.05)"
            : "rgba(0, 0, 0, 0.02)",
        transition: theme.transitions.create(
          ["background-color", "box-shadow", "border-color"],
          {
            duration: 200,
          }
        ),
        "&:hover": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.1)"
              : "rgba(0, 0, 0, 0.04)",
        },
        "&.Mui-focused": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.15)"
              : "rgba(0, 0, 0, 0.06)",
          boxShadow: `0 0 0 1px ${theme.palette.primary.main}25`,
        },
      },
    },
    forgotPasswordContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: { xs: theme.spacing(2), sm: theme.spacing(3) },
    },
    forgotPasswordLink: {
      color:
        theme.palette.mode === "dark"
          ? "rgba(255, 255, 255, 0.7)"
          : "rgba(0, 0, 0, 0.6)",
      textDecoration: "none",
      fontSize: { xs: "0.8rem", sm: "0.875rem" },
      fontWeight: 500,
      transition: "color 0.2s ease-in-out",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    submitButton: {
      padding: { xs: theme.spacing(1.2), sm: theme.spacing(1.5) },
      marginTop: { xs: theme.spacing(0.5), sm: theme.spacing(1) },
      marginBottom: { xs: theme.spacing(1.5), sm: theme.spacing(2) },
      borderRadius: { xs: 3, sm: 4 },
      textTransform: "none",
      fontSize: { xs: "0.95rem", sm: "1rem" },
      fontWeight: 600,
      background: "linear-gradient(45deg, #ff4b6e, #ff758c)",
      boxShadow: "0 2px 8px rgba(255, 75, 110, 0.25)",
      transition: "all 0.2s ease-in-out",
      "&:hover": {
        transform: "translateY(-1px)",
        boxShadow: "0 4px 12px rgba(255, 75, 110, 0.35)",
      },
      "&:active": {
        transform: "translateY(1px)",
      },
    },
    googleButton: {
      padding: { xs: theme.spacing(1.2), sm: theme.spacing(1.5) },
      marginBottom: { xs: theme.spacing(2), sm: theme.spacing(3) },
      borderRadius: { xs: 3, sm: 4 },
      textTransform: "none",
      fontSize: { xs: "0.9rem", sm: "0.95rem" },
      fontWeight: 500,
      color: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255, 255, 255, 0.05)"
          : "rgba(0, 0, 0, 0.02)",
      opacity: 0.75,
      cursor: "not-allowed",
      "&:hover": {
        backgroundColor:
          theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, 0.05)"
            : "rgba(0, 0, 0, 0.02)",
        borderColor: theme.palette.divider,
      },
    },
    link: {
      color: "#ff4b6e",
      textDecoration: "none",
      transition: "all 0.2s ease",
      "&:hover": {
        color: "#ff758c",
      },
    },
    linkContainer: {
      textAlign: "center",
      mt: 2,
    },
    buttonProgress: {
      color: "white",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  };

  return (
    <AuthCard>
      <Box sx={{ textAlign: "center", mb: 3 }}>
        <TypewriterText />
        <Typography variant="subtitle1" color="text.secondary">
          {t("auth.login.subtitle")}
        </Typography>
      </Box>

      {error && (
        <Alert
          severity="error"
          sx={{
            mb: 3,
            borderRadius: 2,
            "& .MuiAlert-icon": {
              color: "error.main",
            },
          }}
        >
          {error}
        </Alert>
      )}

      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <TextField
          fullWidth
          label={t("common.email")}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
          sx={styles.textField}
        />

        <TextField
          fullWidth
          label={t("common.password")}
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={styles.textField}
        />

        <Box sx={{ mb: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                disabled={loading}
              />
            }
            label={t("auth.login.rememberMe")}
          />
        </Box>

        <Box sx={styles.forgotPasswordContainer}>
          <MuiLink
            component={Link}
            to="/forgot-password"
            sx={styles.forgotPasswordLink}
          >
            {t("auth.login.forgotPassword")}
          </MuiLink>
        </Box>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={loading}
          sx={styles.submitButton}
        >
          {loading ? (
            <CircularProgress size={24} sx={styles.buttonProgress} />
          ) : (
            t("auth.login.submit")
          )}
        </Button>

        <Button
          fullWidth
          variant="outlined"
          startIcon={<Google />}
          disabled={true}
          sx={styles.googleButton}
        >
          {t("auth.login.loginWithGoogle")}
        </Button>

        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              "& .MuiLink-root": {
                color: "primary.main",
                textDecoration: "none",
                fontWeight: 500,
                transition: "color 0.2s",
                "&:hover": {
                  color: "primary.dark",
                  textDecoration: "underline",
                },
              },
            }}
          >
            {t("auth.landing.newHostText")}{" "}
            <MuiLink
              component="button"
              onClick={() => navigate("/create-guide")}
            >
              {t("auth.landing.createGuideLink")}
            </MuiLink>
          </Typography>
        </Box>
      </form>
    </AuthCard>
  );
}
