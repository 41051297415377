import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: { xs: '2.5rem', sm: '3rem' },
  fontWeight: 700,
  textAlign: 'center',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1)
}));

const greetings = [
  "Olá",
  "こんにちは",
  "Hola",
  "안녕하세요",
  "Dalhe",
  "Hello",
  "Bonjour",
  "Hallo",
  "你好",
  "Ciao",
  "Beleza?",
  "Привет",
  "Guten Tag",
  "Salut",
  "Buongiorno",
  "Buenos días",
  "Oi",
  "Γεια σου",
  "E aí?",
  "Здравствуйте",
  "How are you?",
  "Привет",
  "مرحبا",
  "你好",
  "こんにちは",
  "Tudo bem?",
  "안녕하세요",
  "नमस्ते",
  "कैसे हो?",
  "Hi",
  "What's up?",
  "Merhaba",
  "Nasılsın?"
];

const TypewriterText = () => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    let timer;

    const typing = () => {
      const fullText = greetings[currentIndex];

      if (isWaiting) {
        setIsWaiting(false);
        setIsDeleting(true);
        return 2000; // Pausa antes de começar a apagar
      }

      if (!isDeleting) {
        if (currentText !== fullText) {
          setCurrentText(fullText.substring(0, currentText.length + 1));
          return 100; // Velocidade de digitação
        } else {
          setIsWaiting(true);
          return 2000; // Pausa com a palavra completa
        }
      } else {
        if (currentText === '') {
          setIsDeleting(false);
          setCurrentIndex((prev) => (prev + 1) % greetings.length);
          return 500; // Pausa antes da próxima palavra
        }
        setCurrentText(fullText.substring(0, currentText.length - 1));
        return 50; // Velocidade de apagar
      }
    };

    timer = setTimeout(() => {
      const delay = typing();
      clearTimeout(timer);
      timer = setTimeout(() => typing(), delay);
    }, 100);

    return () => clearTimeout(timer);
  }, [currentText, currentIndex, isDeleting, isWaiting]);

  return (
    <StyledTypography variant="h3" component="h1">
      {currentText}
      <span style={{ opacity: isDeleting ? 0 : 1 }}>|</span>
    </StyledTypography>
  );
};

export default TypewriterText;
