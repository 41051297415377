import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
  pt: {
    translation: require("./locales/pt/translation.json"),
  },
  en: {
    translation: require("./locales/en/translation.json"),
  },
  fr: {
    translation: require("./locales/fr/translation.json"),
  },
  es: {
    translation: require("./locales/es/translation.json"),
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "pt",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: [
        "sessionStorage",
        "querystring",
        "cookie",
        "localStorage",
        "navigator",
      ],
      caches: ["sessionStorage", "localStorage", "cookie"],
      lookupSessionStorage: "selectedLanguage",
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
