import { Translate } from "@mui/icons-material";
import { Button, DialogContentText, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BaseDialog from "../../../common/dialog/BaseDialog";

const RequestDialog = ({ open, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [requestLanguage, setRequestLanguage] = useState("");
  const [requestReason, setRequestReason] = useState("");

  const handleSubmit = () => {
    onSubmit({
      language: requestLanguage,
      reason: requestReason,
    });
    onClose();
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={t("language.requestTitle", "Solicitar novo idioma")}
      icon={Translate}
      useCustomHeader={true}
      actions={
        <>
          <Button onClick={onClose} variant="outlined">
            {t("common.cancel", "Cancelar")}
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={!requestLanguage.trim() || !requestReason.trim()}
          >
            {t("language.submit", "Enviar")}
          </Button>
        </>
      }
    >
      <DialogContentText sx={{ mb: 3 }}>
        {t("language.requestDescription")}
      </DialogContentText>
      <TextField
        autoFocus
        margin="dense"
        label={t("language.requestLanguageLabel")}
        fullWidth
        variant="outlined"
        value={requestLanguage}
        onChange={(e) => setRequestLanguage(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        margin="dense"
        label={t("language.requestReasonLabel")}
        fullWidth
        multiline
        rows={4}
        variant="outlined"
        value={requestReason}
        onChange={(e) => setRequestReason(e.target.value)}
      />
    </BaseDialog>
  );
};

export default RequestDialog;
