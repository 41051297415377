import { Box } from "@mui/material";

const Logo = ({ darkMode, isExpanded }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mb: 2,
      }}
    >
      <img
        src={
          isExpanded
            ? darkMode
              ? "/images/logo_full_dark.png"
              : "/images/logo_full.png"
            : "/images/logo_square.png"
        }
        alt="Logo"
        style={{
          width: isExpanded ? 150 : 40,
          height: "auto",
        }}
      />
    </Box>
  );
};

export default Logo;
