import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Button, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const UnauthorizedPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "80vh",
          textAlign: "center",
        }}
      >
        <ErrorOutlineIcon sx={{ fontSize: 64, color: "error.main", mb: 2 }} />
        <Typography variant="h4" component="h1" gutterBottom>
          {t("account.accessDenied.title")}
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          {t("account.accessDenied.message")}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/dashboard")}
          sx={{ mt: 2 }}
        >
          {t("common.backToDashboard")}
        </Button>
      </Box>
    </Container>
  );
};

export default UnauthorizedPage;
