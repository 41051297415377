import { get, ref, set } from "firebase/database";
import { database } from "../config/firebase";

// Helper function to create a database reference
const createRef = (path) => ref(database, path);

// Helper function to handle errors
const handleFirebaseError = (error, message) => {
  const errorMessage = `${message}: ${error.message}`;
  console.error(errorMessage, error);
  throw new Error(errorMessage);
};

export async function setUserRole(uid, role = "host") {
  try {
    const roleRef = createRef(`users/${uid}/role`);
    await set(roleRef, role);
    return true;
  } catch (error) {
    handleFirebaseError(error, "Failed to set user role");
  }
}

export async function getUserRole(uid) {
  try {
    const roleRef = createRef(`users/${uid}/role`);
    const snapshot = await get(roleRef);
    return snapshot.exists() ? snapshot.val() : null;
  } catch (error) {
    handleFirebaseError(error, "Failed to retrieve user role");
  }
}

export async function initializeUserData(uid, userData) {
  try {
    const userRef = createRef(`users/${uid}`);
    const snapshot = await get(userRef);

    if (snapshot.exists()) {
      console.log("User data already exists, skipping initialization");
      return true;
    }

    const basicUserData = {
      name: userData.name || "",
      email: userData.email || "",
      role: "host",
      global_settings: {
        language: {
          defaultLanguage: "ptbr",
          enabledLanguages: ["ptbr", "enus", "esp"],
          lastUpdated: new Date().toISOString(),
        },
      },
    };

    await set(userRef, basicUserData);
    return true;
  } catch (error) {
    handleFirebaseError(error, "Error initializing user data");
    return false;
  }
}

export async function addGuestAccess(uid, propertyId, guestEmail, accessData) {
  try {
    const guestId = guestEmail.replace(/[.@]/g, "_");
    const guestAccessRef = createRef(
      `users/${uid}/properties/${propertyId}/guestAccess/${guestId}`
    );

    const guestAccessData = {
      accessGranted: accessData.accessGranted || new Date().toISOString(),
      accessExpires: accessData.accessExpires,
      status: "active",
      email: guestEmail,
    };

    await set(guestAccessRef, guestAccessData);

    // Adiciona referência cruzada na coleção guestAccess
    const guestPropertyRef = createRef(
      `guestAccess/${guestId}/properties/${propertyId}`
    );
    await set(guestPropertyRef, {
      accessGranted: accessData.accessGranted || new Date().toISOString(),
      accessExpires: accessData.accessExpires,
      status: "active",
    });

    return true;
  } catch (error) {
    handleFirebaseError(error, "Error adding guest access");
    return false;
  }
}

// Convert email to Base64URL format
export function emailToBase64URL(email) {
  if (!email) return '';
  const normalizedEmail = email.toLowerCase().trim();
  const base64 = btoa(normalizedEmail);
  return base64
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
}

// Add guest access to Realtime DB
export async function addGuestAccessToDB(hostUid, propertyId, guestEmail, accessData) {
  try {
    const guestId = emailToBase64URL(guestEmail);
    const normalizedEmail = guestEmail.toLowerCase().trim();
    const now = new Date().toISOString();
    
    // Add to global guestAccess collection
    const guestAccessRef = createRef(`guestAccess/${guestId}`);
    const guestSnapshot = await get(guestAccessRef);
    
    if (guestSnapshot.exists()) {
      // Update existing guest access
      const currentData = guestSnapshot.val();
      await set(guestAccessRef, {
        ...currentData,
        email: normalizedEmail,
        lastAccess: now,
        properties: {
          ...currentData.properties,
          [propertyId]: {
            accessExpires: accessData.accessExpires,
            accessGranted: accessData.accessGranted,
            defaultlanguage: accessData.defaultlanguage || "ptbr",
            role: "guest",
            status: "active"
          }
        }
      });
    } else {
      // Create new guest access
      await set(guestAccessRef, {
        email: normalizedEmail,
        lastAccess: now,
        properties: {
          [propertyId]: {
            accessExpires: accessData.accessExpires,
            accessGranted: accessData.accessGranted,
            defaultlanguage: accessData.defaultlanguage || "ptbr",
            role: "guest",
            status: "active"
          }
        }
      });
    }

    // Add to property's guestAccess collection
    const propertyGuestAccessRef = createRef(`properties/${propertyId}/guestAccess/${guestId}`);
    const propertyGuestSnapshot = await get(propertyGuestAccessRef);
    
    if (propertyGuestSnapshot.exists()) {
      // Update existing guest access in property
      const currentData = propertyGuestSnapshot.val();
      await set(propertyGuestAccessRef, {
        ...currentData,
        email: normalizedEmail,
        lastAccess: now,
        properties: {
          [propertyId]: {
            accessExpires: accessData.accessExpires,
            accessGranted: accessData.accessGranted,
            defaultlanguage: accessData.defaultlanguage || "ptbr",
            role: "guest",
            status: "active"
          }
        }
      });
    } else {
      // Create new guest access in property
      await set(propertyGuestAccessRef, {
        email: normalizedEmail,
        lastAccess: now,
        properties: {
          [propertyId]: {
            accessExpires: accessData.accessExpires,
            accessGranted: accessData.accessGranted,
            defaultlanguage: accessData.defaultlanguage || "ptbr",
            role: "guest",
            status: "active"
          }
        }
      });
    }

    return true;
  } catch (error) {
    handleFirebaseError(error, 'Failed to add guest access');
    return false;
  }
}

// Update guest access in Realtime DB
export async function updateGuestAccess(hostUid, propertyId, guestEmail, accessData) {
  try {
    const guestId = emailToBase64URL(guestEmail);
    const now = new Date().toISOString();

    // Update in global guestAccess collection
    const guestAccessRef = createRef(`guestAccess/${guestId}/properties/${propertyId}`);
    const guestSnapshot = await get(guestAccessRef);
    
    if (guestSnapshot.exists()) {
      await set(guestAccessRef, {
        ...guestSnapshot.val(),
        accessExpires: accessData.accessExpires,
        accessGranted: accessData.accessGranted,
        defaultlanguage: accessData.defaultlanguage || "ptbr",
        role: "guest",
        status: accessData.status || "active"
      });
    }

    // Update in property's guestAccess collection
    const propertyGuestRef = createRef(`properties/${propertyId}/guestAccess/${guestId}/properties/${propertyId}`);
    const propertyGuestSnapshot = await get(propertyGuestRef);
    
    if (propertyGuestSnapshot.exists()) {
      await set(propertyGuestRef, {
        ...propertyGuestSnapshot.val(),
        accessExpires: accessData.accessExpires,
        accessGranted: accessData.accessGranted,
        defaultlanguage: accessData.defaultlanguage || "ptbr",
        role: "guest",
        status: accessData.status || "active"
      });
    }

    return true;
  } catch (error) {
    handleFirebaseError(error, 'Failed to update guest access');
    return false;
  }
}

// Verify guest access
export async function verifyGuestAccess(email, propertyId) {
  try {
    const guestId = emailToBase64URL(email);
    const guestRef = createRef(`guestAccess/${guestId}`);
    const snapshot = await get(guestRef);

    if (!snapshot.exists()) {
      return false;
    }

    const guestData = snapshot.val();
    const propertyAccess = guestData.properties?.[propertyId];

    if (!propertyAccess) {
      return false;
    }

    const now = new Date();
    const expiryDate = propertyAccess.accessExpires ? new Date(propertyAccess.accessExpires) : null;

    const hasAccess = 
      propertyAccess.status === 'active' &&
      (!expiryDate || expiryDate > now);

    if (hasAccess) {
      // Update lastAccess timestamp
      await set(createRef(`guestAccess/${guestId}/lastAccess`), new Date().toISOString());
    }

    return hasAccess;
  } catch (error) {
    handleFirebaseError(error, 'Failed to verify guest access');
    return false;
  }
}

// Get guest properties
export async function getGuestProperties(email) {
  try {
    const guestId = emailToBase64URL(email);
    const guestRef = createRef(`guestAccess/${guestId}`);
    const snapshot = await get(guestRef);

    if (!snapshot.exists()) {
      return [];
    }

    const guestData = snapshot.val();
    const now = new Date();
    const activeProperties = [];

    for (const [propertyId, access] of Object.entries(guestData.properties || {})) {
      const expiryDate = access.accessExpires ? new Date(access.accessExpires) : null;
      
      if (
        access.status === 'active' &&
        (!expiryDate || expiryDate > now)
      ) {
        // Fetch property details
        const propertyRef = createRef(`properties/${propertyId}`);
        const propertySnapshot = await get(propertyRef);
        
        if (propertySnapshot.exists()) {
          const propertyData = propertySnapshot.val();
          activeProperties.push({
            id: propertyId,
            name: propertyData.name,
            access: {
              ...access,
              propertyId
            }
          });
        }
      }
    }

    return activeProperties;
  } catch (error) {
    handleFirebaseError(error, 'Failed to get guest properties');
    return [];
  }
}
