import { CssBaseline } from "@mui/material";
import React from "react";
import AppLayout from "./components/core/AppLayout";
import RouteManager from "./components/core/RouteManager";
import ThemeManager from "./components/core/ThemeManager";
import { AuthProvider } from "./contexts/AuthContext";
import { PropertyProvider } from "./contexts/PropertyContext";

const App = () => {
  return (
    <AuthProvider>
      <PropertyProvider>
        <ThemeManager>
          <CssBaseline />
          <AppLayout>
            <RouteManager />
          </AppLayout>
        </ThemeManager>
      </PropertyProvider>
    </AuthProvider>
  );
};

export default App;
