import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import ErrorBoundary from "../ErrorBoundary";
import LoadingSpinner from "../LoadingSpinner";

// Eager load components that are needed immediately
import HostLogin from "../../pages/Auth/Login";
import UnauthorizedPage from "../../pages/Auth/UnauthorizedPage";
import LandingPage from "../../pages/LandingPage";

// Lazy load other components
const Home = React.lazy(() => import("../../pages/Home/Home"));
const PropertyInfo = React.lazy(() =>
  import("../../pages/PropertyInfo/PropertyInfo")
);
const LocalTips = React.lazy(() => import("../../pages/LocalTips/LocalTips"));
const Settings = React.lazy(() => import("../../pages/Settings/Settings"));
const AmenitiesPage = React.lazy(() =>
  import("../../pages/Amenities/AmenitiesPage")
);
const PropertyManagement = React.lazy(() =>
  import("../../pages/PropertyManagement/PropertyManagement")
);
const GuestLogin = React.lazy(() => import("../../pages/Auth/GuestLogin"));
const GuestAccess = React.lazy(() => import("../../pages/Auth/GuestAccess"));
const ResetPassword = React.lazy(() =>
  import("../../pages/Auth/ResetPassword")
);
const CreateGuide = React.lazy(() => import("../../pages/Auth/CreateGuide"));

const RouteManager = ({ darkMode, toggleDarkMode }) => {
  const { currentUser, checkUserRole } = useAuth();
  const guestEmail = sessionStorage.getItem("guestEmail");
  const propertyId = sessionStorage.getItem("propertyId");

  // Check if user is authenticated (either regular user or guest)
  const isAuthenticated = currentUser || (guestEmail && propertyId);

  // Protected Route Component
  const ProtectedRoute = ({ children, hostOnly = false }) => {
    const [hasAccess, setHasAccess] = React.useState(null);

    React.useEffect(() => {
      const checkAccess = async () => {
        if (!isAuthenticated) {
          setHasAccess(false);
          return;
        }
        if (hostOnly) {
          const role = await checkUserRole();
          setHasAccess(role === "host");
        } else {
          setHasAccess(true);
        }
      };
      checkAccess();
    }, [hostOnly]);

    if (hasAccess === null) {
      return <LoadingSpinner />;
    }

    if (!isAuthenticated) {
      return <Navigate to="/host" replace />;
    }

    if (hostOnly && !hasAccess) {
      return <Navigate to="/unauthorized" replace />;
    }

    return children;
  };

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          {/* Root route with conditional redirect */}
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <LandingPage />
              )
            }
          />

          {/* Public Routes - accessible when not authenticated */}
          <Route
            path="/host"
            element={
              isAuthenticated ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <HostLogin />
              )
            }
          />
          <Route
            path="/guest"
            element={
              isAuthenticated ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <GuestLogin />
              )
            }
          />
          <Route path="/forgot-password" element={<ResetPassword />} />
          <Route path="/guest-access/:propertyId" element={<GuestAccess />} />
          <Route path="/unauthorized" element={<UnauthorizedPage />} />

          {/* Protected Routes - require authentication */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Home darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/property-info"
            element={
              <ProtectedRoute>
                <PropertyInfo />
              </ProtectedRoute>
            }
          />
          <Route
            path="/local-tips"
            element={
              <ProtectedRoute>
                <LocalTips />
              </ProtectedRoute>
            }
          />
          <Route
            path="/amenities"
            element={
              <ProtectedRoute>
                <AmenitiesPage />
              </ProtectedRoute>
            }
          />

          {/* Host-only routes */}
          <Route
            path="/settings"
            element={
              <ProtectedRoute hostOnly>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/management"
            element={
              <ProtectedRoute hostOnly>
                <PropertyManagement />
              </ProtectedRoute>
            }
          />
          <Route path="/create-guide" element={<CreateGuide />} />

          {/* Catch all route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default RouteManager;
